import "./App.css";
import SignIn from "./components/SignIn";
import Dashboard from "./components/Dashboard";
import { atom, useRecoilState } from "recoil";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import SignInChecker from "./components/SignInChecker";
import ViewClientCar from "./components/ViewCientCar";
import ViewNews from "./components/ViewNews";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import ViewEvent from "./components/ViewEvent";
import ViewOffer from "./components/ViewOffer";
import ViewUser from "./components/ViewUser";
import ViewShowroomCar from "./components/ViewShowroomCar";
import ViewRequests from "./components/ViewRequests";
import ClientCars from "./components/ClientCars";
import News from "./components/News";
import Events from "./components/Events";
import Offers from "./components/Offers";
import Vignettes from "./components/Vignettes";
import Users from "./components/Users";
import ShowroomCars from "./components/ShowroomCars";
import Requests from "./components/Requests";
import Orders from "./components/Orders";
import PushNotification from "./components/PushNotification";
import ViewOrder from "./components/ViewOrder";
import OfferRequests from "./components/OfferRequests";
import BuybackRequests from "./components/BybackReqests";
import TestDriveRequests from "./components/TestdriveRequests";
import RequestToOrderRequests from "./components/RequestToOrder";
import BmwOptions from "./components/BmwOptions";
import OffersRTB from "./components/OffersRTB";
import ViewRequestToOrder from "./components/ViewRequestToOrder";
import ChatWithAdmin from "./components/ChatWithAdmin";
import RequestsByEmail from "./components/RequestsByEmail";
import OrdersByEmailRequests from "./components/OrdersByEmailRequests";
import BuybackRequestsEmail from "./components/BuyBackRequestsEmail";
import OfferRequestsByEmail from "./components/OfferRequestsByEmail";
import TestdriveRequestsByEmail from "./components/TestdriveRequestsByEmail";
import RequestToOrderEmailRequests from "./components/RequestToOrderEmailRequests";
import BuyBackRequestsEmail from "./components/BuyBackRequestsEmail";
import SupportForm from "./components/SupportForm";
import ViewChat from "./components/ViewChat"; // Import the SupportForm component

const baseUrl = process.env.REACT_APP_BASE_URL;

export const accessTokenState = atom({
  key: "accessTokenState",
  default: null,
});

export const refreshTokenState = atom({
  key: "refreshTokenState",
  default: null,
});

const signedInState = atom({
  key: "userState",
  default: false,
});

function App() {
  const [accessToken, setAccessToken] = useRecoilState(accessTokenState);
  const [refreshToken, setRefreshToken] = useRecoilState(refreshTokenState);
  const [signedIn, setSignedIn] = useRecoilState(signedInState);

  return (
      <BrowserRouter>
        <div className="App">
          <header className="App-header"></header>
        </div>

        <Routes>
          <Route path="/" element={<SignInChecker />} />
          <Route
              path="/signin"
              element={
                <SignIn
                    baseUrl={baseUrl}
                    accessToken={accessToken}
                    refreshToken={refreshToken}
                    setAccessToken={setAccessToken}
                    setRefreshToken={setRefreshToken}
                    setSignedIn={setSignedIn}
                />
              }
          />
          <Route
              path="/dashboard"
              element={
                <Dashboard
                    signedIn={signedIn}
                    baseUrl={baseUrl}
                    accessToken={accessToken}
                    refreshToken={refreshToken}
                />
              }
          />

          <Route path="/support" element={<SupportForm />} />
          <Route path="/client-cars/:id" element={<ViewClientCar />} />
          <Route path="/news/:id" element={<ViewNews />} />
          <Route path="/events/:id" element={<ViewEvent />} />
          <Route path="/offers/:id" element={<ViewOffer />} />
          <Route path="/404" element={<h1>404</h1>} />
          <Route path="/users/:id" element={<ViewUser />} />
          <Route path="/showroom-cars/:id" element={<ViewShowroomCar />} />
          <Route path="/requests/:id" element={<ViewRequests />} />
          <Route path="/orders/:id" element={<ViewOrder />} />
          <Route path="/requests-to-buy/:id" element={<ViewRequestToOrder />} />
          <Route path="/chat/messages/:id" element={<ViewChat />} />

          <Route path="admin/requests/email/:email" element={<RequestsByEmail />} />
          <Route path="admin/orders/email/:email" element={<OrdersByEmailRequests />} />
          <Route path="admin/buyback-requests/email/:email" element={<BuyBackRequestsEmail />} />
          <Route path="admin/testdrive-requests/email/:email" element={<TestdriveRequestsByEmail />} />
          <Route path="/request-to-buy/email/:email" element={<RequestToOrderEmailRequests />} />
          <Route path="admin/offer-requests/email/:email" element={<OfferRequestsByEmail />} />

          <Route
              path="/dashboard/news"
              element={<Dashboard element={<News />} />}
          />
          <Route
              path="/dashboard/client-cars"
              element={<Dashboard element={<ClientCars />} />}
          />
          <Route
              path="/dashboard/events"
              element={<Dashboard element={<Events />} />}
          />
          <Route
              path="/dashboard/offers"
              element={<Dashboard element={<OffersRTB />} />}
          />
          <Route
              path="/dashboard/vignettes"
              element={<Dashboard element={<Vignettes />} />}
          />
          <Route
              path="/dashboard/users"
              element={<Dashboard element={<Users />} />}
          />
          <Route
              path="/dashboard/showroom-cars"
              element={<Dashboard element={<ShowroomCars />} />}
          />
          <Route
              path="/dashboard/requests"
              element={<Dashboard element={<Requests />} />}
          />
          <Route
              path="/dashboard/orders"
              element={<Dashboard element={<Orders />} />}
          />
          <Route path="*" element={<h1>404</h1>} />
          <Route
              path="/dashboard/push-notifications"
              element={<Dashboard element={<PushNotification />} />}
          />
          <Route
              path="/dashboard/offer-requests"
              element={<Dashboard element={<OfferRequests />} />}
          />
          <Route
              path="/dashboard/buyback-requests"
              element={<Dashboard element={<BuybackRequests />} />}
          />
          <Route
              path="/dashboard/testdrive-requests"
              element={<Dashboard element={<TestDriveRequests />} />}
          />
          <Route
              path="/dashboard/request-to-buy"
              element={<Dashboard element={<RequestToOrderRequests />} />}
          />
          <Route
              path="/dashboard/bmw-options"
              element={<Dashboard element={<BmwOptions />} />}
          />
          <Route
              path="/dashboard/chat"
              element={<Dashboard element={<ChatWithAdmin />} />}
          />
        </Routes>
      </BrowserRouter>
  );
}

export default App;
