import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import useAxios from "../utils/useAxios";
import { accessTokenState, refreshTokenState } from "../App";
import jwtDecode from "jwt-decode";
import { useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Row, Col, Card, Table, Input, Modal } from "antd";
import { Layout } from "antd";
import { currentLangState } from "./Dashboard";
import { LocalizationStrings } from "../utils/localization";
import './ChatWithAdmin.css'; // Import the CSS file

function ChatWithAdmin(props) {
    let api = useAxios();

    const strings = LocalizationStrings;

    const [currentLang, setCurrentLang] = useRecoilState(currentLangState); // Default language is English (en

    strings.setLanguage(currentLang);

    const [accessToken, setAccessToken] = useRecoilState(accessTokenState);
    const [refreshToken, setRefreshToken] = useRecoilState(refreshTokenState);
    const [rows, setRows] = useState([]);
    const [editData, setEditData] = useState(null);
    const [inputData, setInputData] = useState(null);

    const [inputOpen, setInputOpen] = useState(false);
    const [updateOpen, setUpdateOpen] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [users, setUsers] = useState([]);
    const [showroomCars, setShowroomCars] = useState([]);

    const [search, setSearch] = useState("");

    const navigate = useNavigate();

    const newsColumns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            width: 75,
        },
        {
            title: strings.email,
            dataIndex: "email",
            key: "email",
        },
        {
            title: strings.message,
            dataIndex: "lastMessage",
            key: "lastMessage",
            render: (text, record) => (
                <span>
                    {record.newMessage && <span className="blue-dot" />} {text}
                </span>
            ),
        },
    ];

    let isMounted = true;

    const fetchData = async (p, isSearchEmpty) => {
        try {
            if (isMounted) {
                const response = await api.get(`/chat`);
                console.log(response);
                setRows(response.data.data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const user = jwtDecode(localStorage.getItem("bovaAccessToken"));
        // console.log(user.role);
        if (user.role !== "admin") {
            alert("not admin");
            props.logOut();
            return;
        }
        setAccessToken(localStorage.getItem("bovaAccessToken"));
        setRefreshToken(localStorage.getItem("bovaRefreshToken"));
        if (
            (localStorage.getItem("bovaAccessToken") === "" &&
                localStorage.getItem("bovaRefreshToken") === "") ||
            (localStorage.getItem("bovaAccessToken") === null &&
                localStorage.getItem("bovaRefreshToken") === null)
        ) {
            navigate("/signin");
        }

        fetchData(page);
        return () => (isMounted = false);
    }, [props.baseUrl]);

    const handleUpdateDialogClickOpen = async (id) => {
        setUpdateOpen(true);
    };

    const handleInputDialogClose = () => {
        setInputData({});
        setInputOpen(false);
    };

    const handleUpdateDialogClose = () => {
        setEditData({});
        setUpdateOpen(false);
    };

    const handleDeleteButtonClickRow = (cellValues) => {
        setDeleteId(cellValues.id);
        setDeleteOpen(true);
    };

    const handleDeleteDialogCancel = () => {
        setDeleteOpen(false);
    };

    const handleDeleteDialogConfirm = async () => {
        setDeleteOpen(false);
        const response = await api.delete(`/chat/messages/${deleteId}`);
        console.log(response);
        if (response.status === 200) {
            props.openNotification(
                "topRight",
                strings.success,
                strings.request + strings.deletedSuccessfullyF
            );
        }

        await fetchData(page);
    };

    return (
        <div
            style={{
                width: 1300,
            }}
        >
            <div>
                <Layout
                    style={{
                        minHeight: "100vh",
                    }}
                >
                    <div className="tabled">
                        <Row gutter={[24, 0]}>
                            <Col xs="24" xl={24}>
                                <Card
                                    bordered={false}
                                    className="criclebox tablespace mb-24"
                                    title={strings.chats}
                                    extra={
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Input
                                                placeholder={strings.search}
                                                prefix={<SearchOutlined />}
                                                size="small"
                                                style={{
                                                    marginRight: 25,
                                                }}
                                                onPressEnter={(event) => {
                                                    setSearch(event.target.value);
                                                    fetchData(page);
                                                }}
                                                onChange={(event) => {
                                                    setSearch(event.currentTarget.value);
                                                    if (event.target.value === "" || null) {
                                                        setSearch(null);
                                                        fetchData(page, true);
                                                    }
                                                }}
                                                value={search}
                                            />
                                        </div>
                                    }
                                >
                                    <div className="table-responsive">
                                        <Table
                                            columns={newsColumns}
                                            dataSource={rows.map(row => ({ ...row, key: row.id }))}
                                            className="ant-border-space"
                                            size="large"
                                            onRow={(record) => {
                                                return {
                                                    onClick: () => {
                                                        navigate(`/chat/messages/${record.userId}`, {
                                                            state: { email: record.email },
                                                        });
                                                    },
                                                };
                                            }}
                                        />
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Layout>
            </div>
            <Modal
                title="Are you sure you want to delete the item?"
                open={deleteOpen}
                onOk={handleDeleteDialogConfirm}
                onCancel={handleDeleteDialogCancel}
            ></Modal>
        </div>
    );
}

export default ChatWithAdmin;
