//make the localized strings using localized strings and export them to use them in other components
import LocalizedStrings from "react-localization";

export let LocalizationStrings = new LocalizedStrings({
  en: {
    bovaAdminPanel: "Bova Admin Panel",
    clientCars: "Client Cars",
    news: "News",
    events: "Events",
    logout: "Log Out",
    offers: "Offers",
    createOffer: "Create Offer",
    offer: "Offer",
    vignettes: "Vignettes",
    vignette: "Vignette",
    createVignette: "Create Vignette",
    users: "Users",
    createUser: "Create User",
    createClientCar: "Create Client Car",
    createNews: "Create News",
    createEvent: "Create Event",
    showroomCars: "Showroom Cars",
    price: "Price",
    title: "Title",
    createdAt: "Created At",
    options: "Options",
    createShowroomCar: "Create Showroom Car",
    transmissionType: "Transmission Type",
    engineType: "Engine Type",
    engineDisplacement: "Engine Displacement",
    enginePower: "Engine Power",
    description: "Description",
    category: "Category",
    edit: "Edit",
    create: "Create",
    deleteConfirmation: "Are you sure you want to delete this item?",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    role: "Role",
    close: "Close",
    durationType: "Duration Type",
    duration: "Duration",
    brand: "Brand",
    model: "Model",
    registrationNumber: "Registration Number",
    vin: "VIN",
    user: "User",
    images: "Images",
    insurance: "Insurance",
    insurances: "Insurances",
    lease: "Lease",
    leases: "Leases",
    monthsNumber: "Months Number",
    paidPaymentsNumber: "Paid Payments",
    paymentsNumber: "Payments Number",
    startsAt: "Starts At",
    insuranceType: "Insurance Type",
    validDataPaymentNumber:
      "Please input months number first and make sure you input valid data.",
    vignettesButton: "Vig.",
    insurancesButton: "Ins.",
    leaseButton: "Lea.",
    delete: "Delete",
    car: "Car",
    noLease: "No Lease",
    interestRate: "Interest Rate",
    downPayment: "Down Payment",
    residualPayment: "Residual Payment",
    endsAt: "Ends At",
    content: "Content",
    date: "Date",
    destinationName: "Destination Name",
    destinationLongitude: "Destination Longitude",
    destinationLatitude: "Destination Latitude",
    notRequired: "Not Required",
    importCSV: "Import CSV",
    password: "Password",
    confirmPassword: "Confirm Password",
    personalIdNumber: "Personal Id Number",
    driverLicenseNumber: "Driver License Number",
    birthDate: "Birth Date",
    phoneNumber: "Phone Number",
    editNotRequired: "еdit Not Required",
    newPassword: "New Password",
    confirmNewPassword: "Confirm New Password",
    requests: "Requests",
    payments: "Payments",
    showroomCar: "Showroom Car",
    orders: "Orders",
    order: "Order",
    status: "Status",
    orderedAt: "Ordered At",
    number: "Number",
    interest: "Interest",
    tax: "Tax",
    principalBalance: "Principal Balance",
    infoDate: "Info Date",
    image: "Image",
    sendPushNotification: "Send Push Notification",
    sendPushNotificationToAll: "Send Push Notification To All Users",
    pushNotification: "Push Notification",
    message: "Message",
    send: "Send",
    panel: "Bova Admin Panel",
    comprehensiveInsurance: "Comprehensive Insurance",
    thirdPartyLiabilityInsurance: "Third Party Liability Insurance",
    week: "Week",
    month: "Month",
    year: "Year",
    day: "Day",
    threeMonths: "3 Months",
    error400:
      "Check your input data or fill it in again if the problem persists.",
    back: "Back",

    //notification loc
    success: "Success!",
    createdSuccessfully: " created successfully!",
    updatedSuccessfully: " updated successfully!",
    deletedSuccessfully: " deleted successfully!",
    addedSuccessfully: " added successfully!",
    createdSuccessfullyF: " created successfully!",
    updatedSuccessfullyF: " updated successfully!",
    deletedSuccessfullyF: " deleted successfully!",
    addedSuccessfullyF: " added successfully!",
    addedSuccessfullyP: " added successfully!",
    createdSuccessfullyN: " created successfully!",
    updatedSuccessfullyN: " updated successfully!",
    deletedSuccessfullyN: " deleted successfully!",
    search: "Search",
    notificationSuccessfullySent: "Notification successfully sent!",

    event: "Event",

    error: "Error!",
    newsSingle: "News",
    imageUploadedSuccessfully: "Image uploaded successfully!",

    request: "Request",
    submit: "Submit",

    updatedAt: "Updated At",

    pleaseInput: "Please input ",
    productionYear: "Production Year",
    mileage: "Mileage",
    offerRequests: "Offer Requests",
    salutation: "Salutation",
    buybackRequests: "Byuback Requests",
    manufactureYear: "Manufacture Year",
    testdriveRequests: "Test Drive Requests",
    phone: "Phone",
    notifyUsers: "Send Push Notification To All Users",
    //new
    yearOfProductionFrom : "Year of production from",
    yearOfProductionTo : "Year of production to",
    priceRangeFrom : "Price range from",
    priceRangeTo: "Price range to",
    requestToBuyRequests : "Request to order ",
    createRequest: "Create request",
    updateRequest: "Update request",
    confirmDelete: "Confirm delete",
    confirmDeleteMessage : "Are you sure you want to delete this request?",
    chats: "Chats",
    isEmailVerified: "Is email verified?",
    Details: "User Details",
    video: "Video",
    update: "Update",
    add: "Add",
    cancel:"Cancel",
    uploadVideo: "Upload video",
    videoUploadedSuccessfully: " video uploaded successfully!",
    errorSendingProblem: "There was an error sending your report.",
    byBovaCar: "Bova Car",
    supportForm: "Support Form",

    emailOptional: "Email (Optional)",
    enterYourEmail: "Enter your email",
    pleaseDescribeYourProblem: "Please describe your problem.",
    describeYourProblem: "Describe your problem.",
    problem: "Problem",
    failedMessage: "Failed to send the message!",
    emptyMessageError: "Fields should not be empty!",
    successfullySentMessage: "Successfully sent message!",
    sentAt: "Sent at:",
    admin: "Admin",
    messages: "Messages",
    loading: "Loading...",
    typeMessage: "Type a message!"
  },
  bg: {
    offer: "Промоция",
    bovaAdminPanel: "Bova Администраторски Панел",
    clientCars: "Клиентски коли",
    news: "Новини",
    events: "Събития",
    logout: "Изход",
    offers: "Промоции",
    vignettes: "Винетки",
    vignette: "Винетка",
    users: "Потребители",
    showroomCars: "Автомобили в шоурума",
    createOffer: "Създай Промоция",
    createVignette: "Създай Винетка",
    createUser: "Създай Потребител",
    createClientCar: "Създай Клиентска Кола",
    createNews: "Създай Новина",
    createEvent: "Създай Събитие",
    price: "Цена",
    title: "Заглавие",
    createdAt: "Създадена на",
    options: "Опции",
    createShowroomCar: "Създай автомобил в шоурума",
    transmissionType: "Тип на скоростната кутия",
    engineType: "Тип на двигателя",
    engineDisplacement: "Обем на двигателя",
    enginePower: "Мощност на двигателя",
    description: "Описание",
    category: "Категория",
    edit: "Редактирай",
    create: "Създай",
    deleteConfirmation: "Сигурни ли сте, че искате да изтриете този елемент?",
    firstName: "Име",
    lastName: "Фамилия",
    email: "Имейл",
    role: "Роля",
    close: "Затвори",
    durationType: "Продължителност",
    duration: "Продължителност",
    brand: "Марка",
    model: "Модел",
    vin: "VIN",
    registrationNumber: "Регистрационен номер",
    user: "Потребител",
    images: "Снимки",
    insurance: "Застраховка",
    insurances: "Застраховки",
    lease: "Лизинг",
    leases: "Лизинги",
    monthsNumber: "Брой месеци",
    paidPaymentsNumber: "Платени вноски",
    paymentsNumber: "Вноски",
    startsAt: "Начална дата",
    insuranceType: "Тип на застраховката",
    validDataPaymentNumber:
      "Моля въведете броя на месеците първо и се уверете, че сте въвели валидни данни.",
    vignettesButton: "Вин.",
    insurancesButton: "Зас.",
    leaseButton: "Лиз.",
    delete: "Изтрий",
    car: "Кола",
    noLease: "Няма лизинг",
    interestRate: "Годишна лихва",
    downPayment: "Първоначална вноска",
    residualPayment: "Остатъчна вноска",
    endsAt: "Крайна дата",
    content: "Съдържание",
    date: "Дата",
    destinationName: "Име на дестинацията",
    destinationLongitude: "Дължина на дестинацията",
    destinationLatitude: "Ширина на дестинацията",
    notRequired: "Не е задължително",
    importCSV: "Импортирай CSV",
    password: "Парола",
    confirmPassword: "Потвърди парола",
    personalIdNumber: "ЕГН",
    driverLicenseNumber: "Номер на шофьорска книжка",
    birthDate: "Дата на раждане",
    phoneNumber: "Телефонен номер",
    editNotRequired: "редактирането не е задължително",
    newPassword: "Нова парола",
    confirmNewPassword: "Потвърди новата парола",
    requests: "Заявки",
    payments: "Плащания",
    showroomCar: "Автомобил в шоурума",
    orders: "Поръчки",
    order: "Поръчка",
    status: "Статус",
    orderedAt: "Поръчана на",
    number: "Номер",
    interest: "Лихва",
    tax: "Такса",
    principalBalance: "Основна сума",
    infoDate: "Дата на информацията",
    image: "Снимка",
    sendPushNotification: "Изпрати Push Нотификация",
    pushNotification: "Push Нотификация",
    message: "Съобщение",
    send: "Изпрати",
    sendPushNotificationToAll: "Изпрати Push нотификация до всички потребители",
    panel: "Bova Администраторски Панел",
    comprehensiveInsurance: "Каско застраховка",
    thirdPartyLiabilityInsurance: "Застраховка Гражданска Отговорност",
    day: "Ден",
    month: "Месец",
    year: "Година",
    week: "Седмица",
    weekend: "Уикенд",
    threeMonths: "Три месеца",
    error400:
      "Проверете въведените данни или ги въведете отново, ако проблемът продължава.",

    //nogification loc
    success: "Успех!",
    createdSuccessfully: " създаден успешно!",
    createdSuccessfullyF: " създадена успешно!",
    createdSuccessfullyN: " създадено успешно!",

    updatedSuccessfully: " обновен успешно!",
    updatedSuccessfullyF: " обновена успешно!",
    updatedSuccessfullyN: " обновено успешно!",

    deletedSuccessfully: " изтрит успешно!",
    deletedSuccessfullyF: " изтрита успешно!",
    deletedSuccessfullyN: " изтрито успешно!",

    addedSuccessfully: " добавен успешно!",
    addedSuccessfullyF: " добавена успешно!",
    addedSuccessfullyP: " добавени успешно!",
    newsSingle: "Новина",
    imageUploadedSuccessfully: "Снимката е качена успешно!",

    event: "Събитие",

    error: "Грешка!",
    search: "Търсене",

    request: "Заявка",

    notificationSuccessfullySent: "Нотификацията е изпратена успешно!",
    submit: "Изпрати",
    updatedAt: "Обновен на",

    pleaseInput: "Моля въведете ",
    productionYear: "Година на производство",
    mileage: "Пробег",
    offerRequests: "Заявки за оферти",
    salutation: "Обръщение",
    buybackRequests: "Заявки за обратно изкупуване",
    manufactureYear: "Година на производство",
    testdriveRequests: "Заявки за тест драйв",
    phone: "Телефон",
    notifyUsers: "Изпрати Push нотификация до всички потребители",
    //new
    yearOfProductionFrom : "Година на производство от",
    yearOfProductionTo : "Година на производство до",
    priceRangeFrom : "Цена от",
    priceRangeTo: "Цена до",
    requestToBuyRequests : "Търся да закупя",
    createRequest: "Създай",
    updateRequest: "Редактирай",
    confirmDelete: "Потвърди изтриване",
    confirmDeleteMessage : "Сигурни ли сте, че искате да изтриете?",
    chats: "Чатове",
    isEmailVerified: "Потвърден ли е имейла?",
    Details: "Информация за потребителя",
    video: "Видео",
    errorSendingProblem: "Има проблем с изпращането на вашият проблем!",
    byBovaCar: "Бова Кар",
    supportForm: "Форма за контакт",
    emailOptional: "Имейл (Незадължителен)",
    enterYourEmail: "Въведете имейл",
    pleaseDescribeYourProblem: "Моля, опишете проблема си",
    describeYourProblem: "Опишете проблема си",
    problem: "Проблем",
    back: "Назад",
    failedMessage: "Неуспешно изпратено съобщение!",
    emptyMessageError: "Полетата не трябева да бъдат празни!",
    successfullySentMessage: "Успешно изпратено съобщение!",
    sentAt: "Изпратено в:",
    admin: "Админ",
    messages: "Съобщения",
    loading: "Зареждане...",
    typeMessage: "Напиши съобщение!"
  },
});
