import React, { useEffect, useState, useRef } from 'react';
import {useParams, useNavigate, useLocation} from 'react-router-dom';
import useAxios from '../utils/useAxios';
import { List, Button, Form, Input, notification } from 'antd';
import dayjs from 'dayjs';
import './ViewChat.css';
import { LocalizationStrings } from "../utils/localization";

const ViewChat = () => {
    const { id } = useParams();
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const api = useAxios();
    const navigate = useNavigate();
    const messagesEndRef = useRef(null);
    const strings = LocalizationStrings;
    const location = useLocation();
    const email = location.state?.email;



    useEffect(() => {
        const fetchChatMessages = async () => {
            try {
                const response = await api.get(`/chat/messages/${id}`);
                setMessages(response.data.data);
            } catch (error) {
                console.error(error);
                notification.error({
                    message: strings.error,
                    description: strings.failedMessage,
                });
            } finally {
                setLoading(false);
            }
        };

        fetchChatMessages();
    }, [id]);

    useEffect(() => {
        if (messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const handleSendMessage = async () => {
        if (!newMessage.trim()) {
            notification.error({
                message: strings.error,
                description: strings.emptyMessageError,
            });
            return;
        }

        const messageData = {
            message: newMessage,
            isAdmin: true,
            receiverId: parseInt(id, 10),
        };

        try {
            const response = await api.post('/chat-messages-admin/admin', messageData);
            setMessages((prevMessages) => [...prevMessages, response.data]);
            setNewMessage('');
            notification.success({
                message: strings.success,
                description: strings.successfullySentMessage,
            });
        } catch (error) {
            console.error(error);
            notification.error({
                message: strings.error,
                description: strings.failedMessage,
            });
        }
    };

    const renderMessage = (message) => (
        <List.Item
            key={message.id}
            className={`message-item ${message.isAdmin ? 'admin-message' : 'user-message'}`}
        >
            <div className={`message-bubble ${message.isAdmin ? 'admin' : 'user'}`}>
                <p className="message-content">
                    <strong>{message.isAdmin ? strings.admin : strings.user}:</strong> {message.message}
                </p>
                <p className="message-timestamp">
                    {dayjs(message.sentAt).format('YYYY-MM-DD HH:mm:ss')}
                </p>
            </div>
        </List.Item>
    );

    return (
        <div className="view-chat-container">
            <header className="chat-header">
                <h2>{email}</h2>
                <Button onClick={() => navigate(-1)} className="back-button">
                    {strings.back}
                </Button>
            </header>

            <div className="message-list-container">
                {loading ? (
                    <p>{strings.loading}</p>
                ) : (
                    <List
                        className="message-list"
                        dataSource={messages}
                        renderItem={renderMessage}
                        bordered={false}
                    />
                )}
                <div ref={messagesEndRef} />
            </div>

            <Form layout="inline" className="message-form" onFinish={handleSendMessage}>
                <Form.Item style={{ flex: 1 }}>
                    <Input
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        placeholder={strings.typeMessage}
                    />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">{strings.send}</Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default ViewChat;
